import React from "react"
import Header from "./header"
import Footer from "./footer"

const LocationContext = React.createContext<Location | undefined>(undefined)

const Layout = ({
  children,
  location,
}: {
  children: React.ReactNode
  location: Location
}) => {
  return (
    <div className="min-h-screen font-body bg-background">
      <LocationContext.Provider value={location}>
        <Header />
        <main>{children}</main>
        <Footer />
      </LocationContext.Provider>
    </div>
  )
}

export const useLocation = () => {
  const context = React.useContext(LocationContext)
  if (!context) {
    throw new Error(`Layout must receive location prop`)
  }
  return context
}

export default Layout
