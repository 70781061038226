import React from "react"
import { debounce } from "./utils"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState({
    width: null,
    height: null,
  })

  React.useEffect(() => {
    setWindowDimensions(getWindowDimensions())
  }, [])

  React.useEffect(() => {
    const handleResize = debounce(() => {
      setWindowDimensions(getWindowDimensions())
    }, 400)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
