import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Image from "gatsby-image"
import { Send, Columns, Home, ChevronDown } from "react-feather"
import { useTransition, animated } from "react-spring"
import { useSelect } from "downshift"

import { useLocation } from "./layout"
import { useWindowDimensions } from "helpers/hooks"

const Data = React.createContext<Record<any, any>>({})

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allMenu_categorys {
          edges {
            node {
              _meta {
                uid
              }
              menu_category_name
            }
          }
        }
      }
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const { width } = useWindowDimensions()
  const isMobile = width < 768

  return (
    <Data.Provider value={data}>
      {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
    </Data.Provider>
  )
}

const useData = () => {
  const data = React.useContext(Data)
  if (!data) {
    throw new Error("useData must be used inside Data Provider")
  }
  return data
}

const mobileLinks = [
  { name: "Home", href: "/", icon: Home },
  { name: "Menu", href: "/menu", icon: Columns },
  { name: "Contact us", href: "/contact", icon: Send },
]

const MobileNavigation = () => {
  const location = useLocation()

  return (
    <aside className="fixed bottom-0 z-50 flex items-center justify-center w-full pt-2 pb-1 bg-white shadow-xl">
      <nav className="w-full">
        <ul className="flex items-center justify-around">
          {mobileLinks.map(({ name, href, icon: Icon }) => {
            const currentPath = `/${location.pathname.split("/")[1]}`
            const isActive = currentPath === href

            return (
              <Link
                activeClassName="text-orange opacity-100"
                className={`${
                  isActive && "text-orange opacity-100"
                } opacity-25`}
                to={href}
                key={name}
              >
                <li className="flex flex-col items-center flex-1 opacity-0 animation-fill-forwards animation-fade-in-up animation-0.5s animation-once animation-ease-in-out">
                  <Icon
                    size={22}
                    className="mb-1 text-current stroke-current"
                  />
                  <p className={`text-current text-sm`}>{name}</p>
                </li>
              </Link>
            )
          })}
        </ul>
      </nav>
    </aside>
  )
}

const desktopLinks = [
  { name: "Menu", href: "/menu", icon: Columns, customRender: renderMenu },
  { name: "Contact us", href: "/contact", icon: Send },
]

const DesktopNavigation = () => {
  const [isTop, setIsTop] = React.useState(true)
  const { pathname } = useLocation()

  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`

  React.useEffect(() => {
    function handleScroll() {
      const newIsTop = window.scrollY < 100
      if (isTop !== newIsTop) {
        setIsTop(newIsTop)
      }
    }

    document.addEventListener("scroll", handleScroll)
    return () => document.removeEventListener("scroll", handleScroll)
  }, [isTop, setIsTop])

  return isTop && rootPath === pathname ? <UncollapsedBar /> : <CollapsedBar />
}

function renderMenu() {
  const { prismic } = useData()
  const categories = React.useMemo(() => {
    const categories: string[] = prismic.allMenu_categorys.edges.map(
      ({ node: { menu_category_name } }) => menu_category_name
    )
    return categories
  }, [])
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useSelect({
    items: categories,
    onSelectedItemChange: ({ selectedItem }) => {
      navigate(`/menu/${selectedItem.toLowerCase()}`)
    },
  })

  const transitions = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      scale: 0.95,
    },
    enter: {
      opacity: 1,
      scale: 1,
    },
    leave: {
      opacity: 0,
      scale: 0.95,
    },
    config: { mass: 0.8, tension: 300, friction: 28 },
  })

  return (
    <div className="relative" onMouseLeave={closeMenu}>
      <button
        {...getToggleButtonProps({ disabled: true })}
        className="inline-flex items-center space-x-2 text-base font-semibold leading-6 transition duration-150 ease-in-out group"
        onMouseOver={openMenu}
      >
        <span>Menu</span>
        <ChevronDown />
      </button>
      <ul
        {...getMenuProps()}
        className="absolute right-0 w-48 max-w-md pt-3 outline-none"
        role="menubar"
      >
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                className="bg-white rounded-lg shadow-lg outline-none"
                key={key}
                style={props}
              >
                {categories.map((category, index) => (
                  <li
                    {...getItemProps({ item: category, index })}
                    key={`${category}${index}`}
                    role="menuitem"
                    className={`${
                      highlightedIndex === index && "bg-gray-200"
                    } relative z-20 grid gap-6 rounded-lg px-5 py-4 transition duration-150 ease-in-out sm:gap-8`}
                  >
                    <Link
                      to={`/menu/${category.toLowerCase()}`}
                      className="flex items-start px-3 py-2 -m-3 space-x-4 text-base font-medium leading-6 text-gray-900 rounded-lg"
                    >
                      {category}
                    </Link>
                  </li>
                ))}
                <div tabIndex={0} />
              </animated.div>
            )
        )}
      </ul>
    </div>
  )
}

const RenderNavigationList = () => {
  return (
    <ul className="flex">
      {desktopLinks.map(({ name, href, customRender }) => {
        if (customRender) {
          return (
            <li key={name} className="px-4">
              {customRender()}
            </li>
          )
        }

        return (
          <li key={name} className="px-4">
            <Link to={href}>{name}</Link>
          </li>
        )
      })}
    </ul>
  )
}

const UncollapsedBar = () => {
  const { logo } = useData()

  return (
    <header className="fixed inset-x-0 z-50 flex items-center justify-end w-full max-w-screen-xl px-8 py-12 mx-auto animation-fade-in-down animation-0.5s animation-once animation-ease-in-out">
      <div className="absolute inset-x-0 flex items-center justify-center">
        <Link to="/">
          <Image
            className="w-32 h-32 mt-16"
            fluid={logo.childImageSharp.fluid}
          />
        </Link>
      </div>
      <nav className="z-10 font-semibold text-white">
        <RenderNavigationList />
      </nav>
    </header>
  )
}

const CollapsedBar = () => {
  const { logo } = useData()

  return (
    <header className="fixed z-50 w-full bg-white border-b-2 border-gray-300 animation-fade-in-down animation-once animation-ease-in-out">
      <div className="flex items-center justify-between max-w-screen-xl px-8 py-5 mx-auto">
        <div>
          <div className="absolute inset-y-0 mt-1">
            <Link to="/">
              <Image className="w-24" fluid={logo.childImageSharp.fluid} />
            </Link>
          </div>
        </div>

        <nav className="z-10 font-semibold text-dark-brown">
          <RenderNavigationList />
        </nav>
      </div>
    </header>
  )
}

export default Header
