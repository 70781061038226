import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { Facebook, Instagram } from "react-feather"
import { css } from "@emotion/core"

const navLinks = [
  { name: "Menu", href: "/menu" },
  { name: "Contact us", href: "/contact" },
]

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            facebook
            instagram
            phone
          }
          address
        }
      }
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const socialLinks = [
    {
      name: "Facebook",
      href: `https://www.facebook.com/${data.site.siteMetadata.social.facebook}`,
      icon: Facebook,
    },
    {
      name: "Instagram",
      href: `https://www.instagram.com/${data.site.siteMetadata.social.instagram}/`,
      icon: Instagram,
    },
  ]

  return (
    <footer
      css={css`
        border-top-width: 1.5px;
        border-color: rgba(0, 0, 0, 0.1);
      `}
      className="px-8 py-16 "
    >
      <div className="flex flex-col items-center max-w-screen-xl mx-auto lg:order-1 lg:flex-row">
        <address className="flex-1 order-2 my-8 not-italic text-center lg:my-0 lg:text-left text-dark-brown">
          <a
            className="text-xl font-semibold"
            href={`tel:${data.site.siteMetadata.social.phone}`}
          >
            {data.site.siteMetadata.social.phone}
          </a>
          <p className="mt-4 text-sm font-medium opacity-75">
            491 Main st. Athol, Massachusetts 01331
            <br />
            United States
          </p>
        </address>
        <div className="flex flex-col items-center flex-1 order-1 lg:order-2">
          <Link to="/">
            <Image className="w-32" fluid={data.logo.childImageSharp.fluid} />
          </Link>
          <ul className="flex mt-6 mb-3">
            {navLinks.map(({ name, href }) => (
              <li key={name} className="px-3 font-medium font-dark-brown">
                <Link to={href}>{name}</Link>
              </li>
            ))}
          </ul>
          <p className="font-medium opacity-75 text-dark-brown">
            Copyright &copy; 2020 Los Agaves Grill
          </p>
        </div>
        <div className="flex-1 order-2">
          <div className="flex flex-col items-center justify-end lg:flex-row">
            <h1 className="mb-4 text-lg font-semibold lg:mb-0 lg:mr-4 xl:text-2xl text-mustard">
              Follow us on
            </h1>
            <div className="flex justify-center">
              {socialLinks.map(props => (
                <SocialButton key={props.name} {...props} />
              ))}
            </div>
          </div>
          <div className="flex justify-center mt-12 text-sm opacity-75 lg:pr-4 lg:justify-end text-dark-brown">
            <Link to="/privacy" className="mr-4">
              Privacy Policy
            </Link>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

const SocialButton = ({ icon: Icon, href, name }) => {
  return (
    <a
      href={href}
      target="_blank"
      className="flex items-center justify-center w-12 h-12 mx-2 rounded-full lg:mr-4 bg-mustard"
    >
      <Icon aria-hidden className="text-white" />
      <p className="sr-only">{name}</p>
    </a>
  )
}

export default Footer
